import {
  launchAnalytics
} from "./matomo";

function initializeAnalytics(analyticsURL, siteID) {
  if (!analyticsURL || !siteID) { // this shouldn't be called since the check is also done server side, but doesn't hurt to leave the code here
    console.log('Not enabling Matomo analytics because of configuration...');
  } else {
    console.log('Enabling Matomo analytics: ', analyticsURL, ' Analytics ID: ', siteID);
    try {
      // tracker methods like "setCustomDimension" should be called before "trackPageView"
      var fullAnalyticsURL = "https://" + analyticsURL + "/"; // use HTTPS explicitly
      window._paq = [
        ['trackPageView'],
        ['enableLinkTracking'],
        ['setTrackerUrl', fullAnalyticsURL + 'matomo.php'],
        ['setSiteId', siteID]
      ];
      launchAnalytics(); // instead of downloading a separate JS file, we'll pack everything inside of Rails assets
      console.log('Matomo analytics enabled...');
    } catch (error) {
      console.warn('Failed to initialize Matomo analytics!', error);
    }
  }
}
if (!window.M.kronis) {
  window.M.kronis = {};
}
window.M.kronis.initializeAnalytics = initializeAnalytics;